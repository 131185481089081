import * as styles from './trackStyles'
import isEmpty from 'lodash.isempty'
import Link from 'next/link'
import { authSelectors } from 'store/auth'
import { EntriesList } from './EntriesList'
import { fetchNextPage } from 'services/actions/entryActions'
import { Fragment, useRef } from 'react'
import { InformationBubble } from '@sc/components/InformationBubble'
import { NavBarAndAlerts } from 'components/NavBarAndAlerts'
import { Loading } from 'components/Loading'
import { logEntriesSelectors } from 'store/logEntries'
import { NewEntry } from '../shared/NewEntry'
import { PageLoader } from 'components/Loading'
import { SampleEntriesGroup } from './SampleEntriesGroup'
import { Tutorial } from 'components/Tutorial'
import { useEntries } from 'hooks/useEntries'
import { useIntersectionObserver } from 'hooks/useIntersectionObserver'
import { useOdoSelector } from 'store/store'
import { useSelector } from 'react-redux'
import { useTags } from 'hooks/useTags'

const NextPageLoader = () => {
  const ref = useRef()

  const isLoading = useOdoSelector(logEntriesSelectors.isLoading)
  const endReached = useOdoSelector(logEntriesSelectors.endReached)

  const canLoadMore = !isLoading && !endReached

  useIntersectionObserver(ref, () => {
    if (canLoadMore) fetchNextPage()
  })

  if (endReached) return null

  return (
    <Fragment>
      {isLoading ? (
        <Loading
          text="Loading more entries..."
          className={styles.loadMoreTrigger()}
        />
      ) : (
        <div
          id="load-more-entries"
          ref={ref}
          className={styles.loadMoreTrigger()}
        />
      )}
    </Fragment>
  )
}

export const Track = () => {
  const { isLoading: tagsLoading } = useTags()
  const { isLoading: entriesLoading } = useEntries()

  const profile = useSelector(authSelectors.profile)
  const entriesGroupedByDay = useOdoSelector(logEntriesSelectors.groupedByDay)

  const initialLoading = tagsLoading || entriesLoading
  const noEntries = !entriesLoading && isEmpty(entriesGroupedByDay)

  if (initialLoading) {
    return (
      <NavBarAndAlerts>
        <PageLoader />
      </NavBarAndAlerts>
    )
  }

  return (
    <NavBarAndAlerts>
      <div className={styles.entriesContainer()}>
        <NewEntry />

        {noEntries && (
          <Tutorial.Dismissable
            item="track"
            css={{ marginBottom: '$spacerYS !important' }}
          >
            <Tutorial.Bubble>
              Hi {profile?.name}, welcome to Odo!
            </Tutorial.Bubble>

            <Tutorial.Bubble>
              A tool to record your journey: Odo uses a simple start/stop timer
              and colourful tags to create time entries . Later, you&apos;ll use
              these tags to calculate time spent on a project, log billable
              hours, measure progress, and more! You can hit Start and create
              #tags on the fly, or view a quick tutorial on how Odo works...
            </Tutorial.Bubble>

            <Tutorial.Action
              as={Link}
              // @ts-expect-error
              href="/guide/tutorials"
              variant="secondary"
            >
              Quick tutorial
            </Tutorial.Action>
          </Tutorial.Dismissable>
        )}

        <SampleEntriesGroup />

        <EntriesList entriesGroup={entriesGroupedByDay} />

        <NextPageLoader />
      </div>
    </NavBarAndAlerts>
  )
}
