import { useCurrentEntry } from 'hooks/useCurrentEntry'
import { TimeEntry } from 'types/timeEntry'
import { EntryCard } from '../EntryCard'

export const NewEntry = () => {
  const {
    currentEntry,
    toggleTimer,
    setDescription,
    addManualEntry,
    setEntryDates
  } = useCurrentEntry()

  return (
    <EntryCard
      entry={currentEntry as TimeEntry}
      value={{
        description: currentEntry.description,
        nodes: currentEntry.nodes
      }}
      onChange={setDescription}
      onEntryDatesChange={setEntryDates}
      onAddManual={addManualEntry}
      toggleTimer={toggleTimer}
      css={{
        position: 'sticky',
        top: 0,
        left: '$paddingUIXL',
        right: '$paddingUIXL',
        marginBottom: '$spacerYS',
        boxShadow: '$nav',
        borderBottomLeftRadius: '$M',
        borderBottomRightRadius: '$M',
        zIndex: '$navigation'
      }}
      showActionButton
      autoFocus
      forceAddTagButton
    />
  )
}
