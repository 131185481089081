import * as styles from './trackStyles'
import React from 'react'
import { DayEntry } from 'modules/shared/DayEntry'
import { EntriesGroupedByDay } from 'types/timeEntry'
import { Divider } from '@sc/components/Divider'
import TimeEntry from 'modules/shared/TimeEntry/TimeEntry'

type EntriesListProp = {
  entriesGroup: EntriesGroupedByDay
}

export const EntriesList = React.memo(function EntriesList({
  entriesGroup
}: EntriesListProp) {
  return (
    <div className={styles.groups()}>
      {Object.keys(entriesGroup).map(date => (
        <DayEntry
          key={date}
          date={entriesGroup[date].date}
          totalSeconds={entriesGroup[date].totalSeconds}
          showTime
        >
          {entriesGroup[date].entries.map(id => (
            <React.Fragment key={id}>
              <Divider />
              <TimeEntry id={id} showAvatar={false} disabled={false} />
            </React.Fragment>
          ))}
        </DayEntry>
      ))}
    </div>
  )
})
